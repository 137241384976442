<template>
  <div class="project">
    <div class="project-con">
      <div><router-link tag="a" to="/projects" class="projects">PROJECTS</router-link></div>
      <div><router-link tag="a" to="/addproject" class="add-project">ADD PROJECT</router-link></div>
    </div>
    <div class="project-table">
      <table border="0" style="width: 70%">
        <tr>
          <td><h3>Project Name</h3></td>
          <td><h3>Create Date</h3></td>
          <td><h3>Type</h3></td>
          <td><h3>Status</h3></td>
        </tr>
        <tr>
          <td colspan="4"><hr style="width: 100%" /></td>
        </tr>
        <tr v-for="item in 5" :key="item.index">
          <td style="padding: 10px; border-bottom: 1px solid">Sempeak</td>
          <td style="border-bottom: 1px solid">23.07.2021</td>
          <td style="border-bottom: 1px solid">Main</td>
          <td style="border-bottom: 1px solid">Enable</td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
export default {};
</script>

<style scoped>
td{
  border-right: 1px solid;
  text-align: center;
}
.project {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.project-con {
  display: inline-grid;
  justify-content: flex-end;
  margin-right: 9%;
  margin-top: 5vh;
 
}
.project-con>div{
    margin-bottom: 25px;
}
.project-con a{
    text-decoration: none;
    color: white;
    font-weight: 500;
    background-color: #474959;
    padding: 5px 5px;
    cursor: pointer;
}
.project-table {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}
</style>